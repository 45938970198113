import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
    ReactElement,
} from 'react';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { AuthContext, UserInfo, initialUserInfo } from 'Contexts/AuthContext';
import useSkymindBackendEndpoints from 'hooks/useSkymindBackendEndpoints/index';

const companyInitialState: Company = {
    address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        country: {
            code: '',
            name: '',
        },
        geolocation: {
            latitude: null,
            longitude: null,
        },
        zipCode: '',
    },
    companyRoles: [],
    companyType: null,
    contactInfo: {
        contactPerson: '',
        email: '',
        phoneNumber: '',
    },
    energyLevelThreshold: null,
    id: null,
    logoImagePath: '',
    name: '',
    serviceProviderCompanies: [],
    website: '',
};

export interface CurrentUserContextInterface {
    company: Company,
    companyInitialState: Company,
    logout: () => void,
    notificationsCount: number,
    setCompany: (company: Company) => void,
    updateNotificationsCount: Function,
    userInfo: UserInfo
}

export const CurrentUserContext = createContext<CurrentUserContextInterface>({
    company: companyInitialState,
    companyInitialState,
    logout: () => {},
    notificationsCount: 0,
    setCompany: null,
    updateNotificationsCount: () => {},
    userInfo: initialUserInfo,

});

type Props = {
    children: ReactElement,
}

const CurrentUserProvider = ({ children }: Props) => {
    const { logout, userInfo } = useContext(AuthContext);
    const [company, setCompany] = useState<Company>(companyInitialState);
    const { FlexibleRequest: getNotificationCount } = useSkymindBackendEndpoints('notifications/count/unread').requests;
    const [shouldUpdate, setShouldUpdate] = useState(Date.now());
    const [notificationsCount, setNotificationsCount] = useState(0);
    const updateNotificationsCount = useCallback(() => setShouldUpdate(Date.now()), []);

    useEffect(() => {
        (async () => {
            try {
                const { value = 0 } = (await getNotificationCount('GET')).data || {};

                setNotificationsCount(value);
            } catch (error) {
                global.console.log(error);
            }
        })();
    }, [shouldUpdate]);

    return (
        <CurrentUserContext.Provider
            value={{
                company,
                companyInitialState,
                logout,
                notificationsCount,
                setCompany,
                updateNotificationsCount,
                userInfo,
            }}
        >
            { children }
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;
