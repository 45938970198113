import { EMAIL_PATTERN } from 'shared-components/constants';
import {
    AddPharmaSiteRequestBody,
    AddCompanyRequestBody,
    AddServiceProviderSiteRequestBody,
    AddUserInfo,
} from 'dataTypes/SecureBackend/processedData';

export const isReadyCompanyRequestBody = (requestBody: AddCompanyRequestBody = null) => {
    if (!requestBody) {
        return false;
    }

    if (requestBody?.contactInfo?.email && !EMAIL_PATTERN.test(requestBody.contactInfo.email)) {
        return false;
    }

    if (requestBody.companyName && requestBody.companyType
        && requestBody.address?.addressLine1 && requestBody.address.countryCode
        && requestBody.address?.geolocation?.latitude && requestBody.address?.geolocation?.longitude) {
        return true;
    }

    return false;
};

export const isReadyPharmaSiteRequestBody = (requestBody: AddPharmaSiteRequestBody = null) => {
    if (!requestBody) {
        return false;
    }

    if (requestBody?.contactInfo?.email && !EMAIL_PATTERN.test(requestBody.contactInfo.email)) {
        return false;
    }

    if (requestBody.address.addressLine1 && requestBody.address.countryCode
        && requestBody.address.geolocation.latitude && requestBody.address.geolocation.longitude) {
        return true;
    }

    return false;
};

export const isReadyServiceProviderSiteBody = (requestBody: AddServiceProviderSiteRequestBody = null) => {
    if (!requestBody) {
        return false;
    }

    if (requestBody?.contactInfo?.email && !EMAIL_PATTERN.test(requestBody.contactInfo.email)) {
        return false;
    }

    if (requestBody.address.addressLine1 && requestBody.address.countryCode
        && requestBody.address.geolocation.latitude && requestBody.address.geolocation.longitude) {
        return true;
    }

    return false;
};

export const isReadyAddUserRequestBody = (requestBody: AddUserInfo) => {
    if (!requestBody) {
        return false;
    }

    if (requestBody.firstName && requestBody.lastName
        && requestBody.email && requestBody.companyId && EMAIL_PATTERN.test(requestBody.email)) {
        return true;
    }

    return false;
};

export const extractOnlyNotNullFields = <T = unknown>(rawObject: T) => {
    return Object.keys(rawObject).reduce((data, field) => {
        return rawObject[field]
            ? { ...data, [field]: rawObject[field] }
            : data;
    }, {});
};

export const initialUserFiltersAdmin = {
    companyNames: [],
    userRoles: [],
};
export const initialUserFiltersUser = {
    userRoles: [],
};
