import { useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import useJWTToken from 'hooks/useJWTToken';
import { getConfig, getQueriesUnwrapped } from '../useSkymindBackendEndpoints/lib';
import useStatusStateProcessOptions from '../useStatusStateProcessOptions';
import useCustomTranslation from '../useCustomTranslation';

const useDownloadExcel = (filterOptions, isAdmin = false) => {
    const token = useJWTToken();
    const { t } = useCustomTranslation();
    const config = useMemo(() => getConfig(token, {}), [token]);
    const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);
    const { setErrorStatus } = useStatusStateProcessOptions();

    const env = process.env.REACT_APP_ENVIRONMENT;

    const downloadExcelHandler = useCallback(async () => {
        setDownloadButtonLoading(true);
        try {
            const queryParams = getQueriesUnwrapped(filterOptions);

            const response = await axios
                .get(isAdmin
                    ? env === 'prod'
                        ? `https://api.skymind.com/admin/assets/export${queryParams === '?'
                            ? '' : queryParams}`
                        : `https://api.${env}.skymind.com/admin/assets/export${queryParams === '?'
                            ? '' : queryParams}`
                    : env === 'prod' ? `https://api.skymind.com/assets/export${queryParams === '?'
                        ? '' : queryParams}`
                        : `https://api.${env}.skymind.com/assets/export${queryParams === '?' ? '' : queryParams}`, {
                    ...config,
                    responseType: 'blob',
                });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const date = moment().format('yyyy-MM-DD');
            const filename = `Assets_${date}.xlsx`;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            global.console.error('Error downloading the file:', error);
            setErrorStatus(error?.response?.data?.errorMessage || t('TRACK_AND_TRACE.ERROR_DOWNLOADING_FILE'));
        } finally {
            setDownloadButtonLoading(false);
        }
    }, [filterOptions, config, setErrorStatus, t]);

    return { downloadButtonLoading, downloadExcelHandler };
};

export default useDownloadExcel;
