import React, {
    useCallback,
    useEffect, useMemo,
    useState,
} from 'react';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import { FacetDTO } from 'shared-components/dataTypes';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import StandalonePagination from 'shared-components/StandalonePagination';
import {
    GenericShipmentDTO,
    initialShipmentFilterOptions,
} from 'TrackAndTrace/GenericShipments/lib';
import {
    CustomSort,
    trimQuery,
} from 'TrackAndTrace/lib';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { getOptionsFromFacets } from 'shared-components/common';
import { styles } from './Shipments.style';
import ShipmentFilterPanel from './ShipmentFilters';
import tableColumns from './tableColumns';

const PAGE_SIZE = 10;

const GenericShipments = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { getFilter, setFilter } = useFilter(PAGES.GENERIC_SHIPMENTS);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [shipments, setShipments] = useState<object[]>([]);
    const [query, setQuery] = useState(filter?.query || '');
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);
    const [highlightedText, setHighlightedText] = useState('');

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialShipmentFilterOptions);

    const {
        data: rawInitialFacets,
        status: rawInitialFacetsStatus,
    } = useGetCommonData<FacetDTO[]>('admin/v2/shipments/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('admin/v2/shipments/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawShipments, status: shipmentsStatus,
    } = useGetCommonData<GenericShipmentDTO>('admin/v2/shipments/search', {
        cache: true,
        enabled: true,
        query: {
            asc: false,
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort)),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    useEffect(() => {
        setHighlightedText(query);
    }, [query]);

    useEffect(() => {
        if (shipmentsStatus === 'SUCCESS' && rawShipments?.resultList) {
            setTotalElements(rawShipments.totalElements);
            setShipments(rawShipments?.resultList);
        }
    }, [shipmentsStatus, rawShipments]);

    useEffect(() => {
        if (Object.values(filterOptions).some(options => options.length !== 0)) return;

        if (rawInitialFacets && rawInitialFacetsStatus === 'SUCCESS') {
            setFilterOptions({
                dataOwners: getOptionsFromFacets(rawInitialFacets, 'dataOwners'),
            });
        }
    }, [rawInitialFacets, rawInitialFacetsStatus]);

    const columns = useMemo(() => tableColumns(t, highlightedText), [t, highlightedText]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    useEffect(() => {
        setFilter({
            filterOptions,
            page,
            query,
            sort,
            totalElements,
        });
    }, [page, totalElements, filterOptions, sort, query]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: shipmentsStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements,
        }}
        >
            <PageWithFilter>
                <ShipmentFilterPanel
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    initialFacets={rawInitialFacets}
                    query={query}
                    setFilterOptions={setFilterOptions}
                    setQuery={setQuery}
                    showHeader={false}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    overflow: 'auto',
                    width: '100%',
                }}
                >
                    <Table
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        columns={columns}
                        currentSort={sort}
                        data={shipments}
                        maskForHighlight={highlightedText}
                        rowLinkTemplate="/administration/shipments/:id"
                        title={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                        onSort={updateSort}
                    />
                    <StandalonePagination
                        detached
                        entity={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                    />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default GenericShipments;
