import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PageWithFilter } from 'Layout';
import { MAP_CONFIG } from 'shared-components/constants';
import { FacetDTO, GatewayWithCount } from 'shared-components/dataTypes';
import { styles } from 'TrackAndTrace/Assets/Assets.style';
import { lyingTimeSwitcherOptions } from 'TrackAndTrace/Assets/components/AssetFilters/AssetFilterPanel';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import useGetCommonData from 'hooks/useGetCommonData';
import { AssetsDTO } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Table from 'shared-components/Table';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import AddAssets from 'TrackAndTrace/Assets/components/AddAssets';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import StandalonePagination from 'shared-components/StandalonePagination';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import useDownloadExcel from 'hooks/useDownloadExcel';
import useFilter, { PAGES } from 'Contexts/useFilter';
import { filterFullWidth } from 'shared-components/SideFilter/SideFilter.style';
import useGetAirports from 'hooks/useGetAirports';
import useStatusState from 'hooks/useStatusState';
import useGetGateways from 'hooks/useGetGateways';
import { facetsConvert } from 'utils/stringTool';
import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import AlertIcon from 'StatusMessages/components/AlertIcon';
import { extractAssetData, ExtractedAssetData, initialAssetFilterOptions } from './lib';

import AssetFilterPanel from './components/AssetFilters';

import MapView from './MapView';
import tableColumns from './tableColumns';

const PAGE_SIZE_TABLE = 40;
const PAGE_SIZE_MAP = 1000;

export const ZOOM_THRESHOLD = 13;

const FILTERS_WITH_NULL_CHECK = ['iataCodes', 'areas'];
const FILTERS_WITH_NULL_NO_CHECK = ['countries', 'cities'];
const FILTERS_WITH_NULL = [...FILTERS_WITH_NULL_CHECK, ...FILTERS_WITH_NULL_NO_CHECK];
const Assets = () => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const view = useGetViewTypeFromQuery();
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);
    const { getFilter, setFilter } = useFilter(PAGES.ASSETS);
    const filter = getFilter();
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [areaLyingSince, setAreaLyingSinceTimestamp] = useState<number | null>(
        filter?.areaLyingSince || null);
    const [locationLyingSince, setLocationLyingSinceTimestamp] = useState<number | null>(
        filter?.locationLyingSince || null);
    const [assets, setAssets] = useState<ExtractedAssetData[]>([]);
    const [areaLyingTime, setAreaLyingTime] = useState(filter?.areaLyingTime || 'All');
    const [locationLyingTime, setLocationLyingTime] = useState(filter?.locationLyingTime || 'All');
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);
    const [showGateways, setShowGateways] = useState(filter?.showGateways || false);
    const columns = useMemo(() => tableColumns(t), [t]);
    const [activeLyingTimeSwitchState, setActiveLyingTimeSwitchState] = useState<string>(
        filter?.activeLyingTime || lyingTimeSwitcherOptions[0]);

    const availableHeight = useAvailableHeight();
    const {
        enableTooltips,
        setHasOnboardingTour,
        setOnboardingTooltipInfo,
    } = useContext(QuickHelpContext);

    const {
        setAlerts,
    } = useStatusState();
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('assets/search/facets',
        {
            cache: true,
            postProcess: (data: FacetDTO[]) => facetsConvert(data, FILTERS_WITH_NULL),
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const rawAirports = useGetAirports();
    const gateways = useGetGateways();

    const PAGE_SIZE = useMemo(() => (view === 'table' ? PAGE_SIZE_TABLE : PAGE_SIZE_MAP), [view]);
    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialAssetFilterOptions);

    const isAirportsAndLocationsShowingMode = useMemo(() => zoom < ZOOM_THRESHOLD, [zoom]);

    const queryObject = useMemo(() => ({
        ...(trimQuery(filterOptions, query, rawInitialFacets)),
        ...(areaLyingSince !== null ? { areaLyingSince } : {}),
        ...(locationLyingSince !== null ? { locationLyingSince } : {}),
    }), [
        filterOptions,
        query,
        rawInitialFacets,
        areaLyingSince,
        locationLyingSince,
    ]);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('assets/search/facets',
        {
            cache: true,
            postProcess: (data: FacetDTO[]) => facetsConvert(data, FILTERS_WITH_NULL),
            query: queryObject,
            queryWrap: false,
        });
    const {
        data: gatewaysWithCount = [],
    } = useGetCommonData<GatewayWithCount[]>('assets/search/asset-count-by-gateway',
        {
            cache: true,
            query: queryObject,
            queryWrap: false,
        });

    const airportsAndLocations = useMemo(() => {
        if (!gatewaysWithCount || gatewaysWithCount.length === 0) {
            return [];
        }

        const airportData = rawAirports
            .filter(airport => gatewaysWithCount.some(it => it.iataCode === airport.code))
            .map(airport => {
                const assetsCount = gatewaysWithCount.filter(gateway => gateway.iataCode === airport.code)
                    .reduce((acc, gateway) => acc + gateway.assetsCount, 0);

                return {
                    ...airport,
                    count: assetsCount || 0,
                    isAirport: true,
                };
            });

        const rawLocations = Array.from(new Set(gatewaysWithCount
            .filter(gateway => !gateway.iataCode).map(gateway => gateway.locationName)));
        const locationData = rawLocations.map(locationName => {
            const assetsCount = gatewaysWithCount
                .filter(gateway => gateway.locationName === locationName && !gateway.iataCode)
                .reduce((acc, gateway) => acc + gateway.assetsCount, 0);

            const geolocation = gatewaysWithCount
                .find(gateway => gateway.locationName === locationName && !gateway.iataCode)?.geolocation;

            return {
                code: locationName,
                count: assetsCount || 0,
                geolocation: geolocation || {},
                isAirport: false,
                locationName,
            };
        });

        return [airportData, locationData].flat();
    }, [gatewaysWithCount, rawAirports]);

    const gatewayArea = useMemo(() => {
        if (!gatewaysWithCount || gatewaysWithCount.length === 0) return [];

        const summedGateways = gatewaysWithCount.reduce((acc, gatewaywc) => {
            const found = acc.find(g => g.gatewayImeiMac === gatewaywc.gatewayImeiMac);

            if (found) {
                found.assetsCount += gatewaywc.assetsCount;
            } else {
                const gatewayElement = gateways.find(g => g.gatewayImeiMac === gatewaywc.gatewayImeiMac);

                if (gatewayElement) {
                    acc.push({
                        ...gatewayElement,
                        assetsCount: gatewaywc.assetsCount,
                    });
                }
            }

            return acc;
        }, []);

        return summedGateways.map(gateway => ({
            ...gateway,
            code: gateway.gatewayImeiMac,
            count: gateway.assetsCount,
            geolocation: {
                latitude: gateway.geolocation.latitude,
                longitude: gateway.geolocation.longitude,
            },
            isAirport: false,
        }));
    }, [gateways, gatewaysWithCount]);

    const mapElements = useMemo(() => {
        return isAirportsAndLocationsShowingMode
            ? airportsAndLocations
            : gatewayArea;
    }, [isAirportsAndLocationsShowingMode, airportsAndLocations, gatewayArea]);

    const {
        data: rawAssets, status: assetsStatus,
    } = useGetCommonData<AssetsDTO>('assets/search', {
        cache: true,
        enabled: facetsStatus === 'SUCCESS' && view === 'table',
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            ...(areaLyingSince !== null ? { areaLyingSince } : {}),
            ...(locationLyingSince !== null ? { locationLyingSince } : {}),
        },
        queryWrap: false,
        refetchOnMount: true,
    });

    const handleAreaLyingTimeChange = useCallback((days) => {
        if (days === null) {
            setAreaLyingSinceTimestamp(null);
        } else {
            setAreaLyingSinceTimestamp(days);
        }
    }, [setAreaLyingSinceTimestamp]);

    const handleLocationLyingTimeChange = useCallback((days) => {
        if (days === null) {
            setLocationLyingSinceTimestamp(null);
        } else {
            setLocationLyingSinceTimestamp(days);
        }
    }, [setLocationLyingSinceTimestamp]);

    const {
        data: geofences,
    } = useGetCommonData<Geofence[]>('geofences', { query: {} });
    const { downloadButtonLoading, downloadExcelHandler } = useDownloadExcel(
        trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets));

    useEffect(() => {
        setHasOnboardingTour(view === 'map');
        return () => {
            setHasOnboardingTour(false);
        };
    }, [view]);

    useEffect(() => {
        if (assetsStatus === 'SUCCESS' && rawAssets?.resultList) {
            const allAssets = extractAssetData(rawAssets?.resultList);

            setTotalElements(rawAssets.totalElements);
            setAssets(allAssets);
        }
    }, [assetsStatus, rawAssets]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.max(Math.ceil(totalElements / PAGE_SIZE), 1));
    }, [page, totalElements]);

    useEffect(() => {
        setOnboardingTooltipInfo({
            core: ONBOARDING_TYPE.ASSET,
            order: 5,
            padding: 0,
            position: 'auto',
            text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
            title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
            uid: 'supportTicketCreationQuickHelpAsset',
        });
        return () => {
            setOnboardingTooltipInfo(null);
        };
    }, []);

    useEffect(() => {
        setFilter({
            activeLyingTime: activeLyingTimeSwitchState,
            areaLyingSince,
            areaLyingTime,
            filterOptions,
            locationLyingSince,
            locationLyingTime,
            page,
            query,
            queryObject,
            showAirportsInfo,
            sort,
            totalElements,
        });
    }, [
        page,
        totalElements,
        query,
        queryObject,
        filterOptions,
        sort,
        showAirportsInfo,
        areaLyingTime,
        locationLyingTime,
        areaLyingSince,
        locationLyingSince,
        activeLyingTimeSwitchState,
    ]);

    useEffect(() => {
        if (view === 'map') {
            setPage(1);
        }
        setZoom(MAP_CONFIG.zoom);
    }, [view]);

    const nullFilterUpdate = useMemo(() => FILTERS_WITH_NULL_CHECK.map(it => filterOptions[it])
        .some(it => it?.includes('null')), [filterOptions]);
    const pairingStatusFilterToUpdate = useMemo(() => filterOptions?.['pairingStatuses']?.includes('NOT_PAIRED'),
        [filterOptions]);

    useEffect(() => {
        const alerts = [];

        const isNotNullNotPairedAlert = filterOptions['pairingStatuses'].includes('NOT_PAIRED')
            || FILTERS_WITH_NULL_CHECK.some(v => filterOptions[v].includes('null'))
            || filterOptions['pairingStatuses'].length === 0 || filterOptions['areas'].length === 0;

        if (isNotNullNotPairedAlert && view === 'map' && !enableTooltips) {
            alerts.push({
                alertColor: 'info_static',
                alertTime: Date.now(),
                icon: <AlertIcon color="info_static" />,
                id: 'notNullNotPairedAlert',
                message: t('TRACK_AND_TRACE.NOT_PAIRED_AND_NULL_ONLY_TABLE'),
                single: true,
            });
        }

        setAlerts(alerts);

        return () => {
            setAlerts([]);
        };
    }, [nullFilterUpdate, filterOptions, pairingStatusFilterToUpdate, enableTooltips, view, t]);

    const mapTotalElements = useMemo(() => airportsAndLocations.reduce((acc, it) => acc + it.count, 0),
        [airportsAndLocations]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            paginationLoading: assetsStatus === 'PENDING',
            perPage: PAGE_SIZE,
            setPage,
            totalElements: view === 'table' ? totalElements : mapTotalElements,
        }}
        >
            <PageWithFilter>
                <AssetFilterPanel
                    activeLyingTime={activeLyingTimeSwitchState}
                    areaLyingTime={areaLyingTime}
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    filterOptions={filterOptions}
                    geofences={geofences}
                    initialFacets={rawInitialFacets}
                    isTableView={view === 'table'}
                    locationLyingTime={locationLyingTime}
                    query={query}
                    setActiveLyingTime={setActiveLyingTimeSwitchState}
                    setAreaLyingTime={setAreaLyingTime}
                    setFilterOptions={setFilterOptions}
                    setLocationLyingTime={setLocationLyingTime}
                    setQuery={setQuery}
                    setShowAirportsInfo={setShowAirportsInfo}
                    setShowGateways={setShowGateways}
                    showAirports={showAirportsInfo}
                    showGateways={showGateways}
                    onAreaLyingTimeChange={handleAreaLyingTimeChange}
                    onLocationLyingTimeChange={handleLocationLyingTimeChange}
                />
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    maxHeight: availableHeight,
                    width: `calc(100% - ${filterFullWidth}px)`,
                }}
                >
                    <AddAssets />
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname="/asset-management/assets"
                        tooltipInfo={{
                            core: ONBOARDING_TYPE.ASSET,
                            isMain: true,
                            order: 4,
                            position: 'center',
                            text: t('ONBOARDING.ASSET_MANAGEMENT.LEGEND_DESC'),
                            title: t('ONBOARDING.ASSET_MANAGEMENT.LEGEND'),
                            uid: 'ASSET_FilterSearch',
                        }}

                    />
                    {
                        view === 'table'
                            ? (
                                <Table
                                    classNames={{
                                        tableContainerClassName: classes.filteredTable,
                                    }}
                                    columns={columns}
                                    currentSort={sort}
                                    data={assets}
                                    downloadButtonLoading={downloadButtonLoading}
                                    maskForHighlight={query}
                                    rowLinkTemplate="/asset-management/assets/location/:assetNumber"
                                    tableId="assetsTable"
                                    tableMaxHeight="100%"
                                    title={t('MENU_ITEMS.TRACK_AND_TRACE_ASSETS')}
                                    onDownloadExcel={downloadExcelHandler}
                                    onSort={updateSort}
                                />
                            )
                            : (
                                <MapView
                                    gateways={gateways}
                                    geofences={geofences}
                                    isAirportShowingMode={isAirportsAndLocationsShowingMode}
                                    mapElements={mapElements}
                                    setZoom={setZoom}
                                    showAirportsInfo={showAirportsInfo}
                                    showGateways={showGateways}
                                    zoom={zoom}
                                />
                            )
                    }
                    {
                        view === 'table' && <StandalonePagination detached entity="Assets" />
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Assets;
