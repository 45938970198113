import React from 'react';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import LoggerSensorDataContainer from './LoggerSensorDataContainer';
import AssetSensorDataContainer from './AssetSensorDataContainer';
import AssetPairings from './AssetPairings';
import LocationHistory from './LocationHistory';

const SensorDataRouter: RouteItem[] = [
    {
        element: <LoggerSensorDataContainer />,
        path: [
            '/track-and-trace/loggers/:entityId',
        ],
        sufficientRoles: [
            userRoles.LOGGER_MANAGEMENT,
            userRoles.ASSET_MANAGEMENT,
        ],
    },
    {
        element: <LoggerSensorDataContainer core="asset-management" />,
        path: [
            '/asset-management/loggers/:entityId',
        ],
        sufficientRoles: [
            userRoles.LOGGER_MANAGEMENT,
            userRoles.ASSET_MANAGEMENT,
        ],
    },
    {
        element: <AssetSensorDataContainer />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: [
            '/asset-management/assets/readout/:entityNumber',
        ],
    },
    {
        element: <AssetPairings />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: [
            '/asset-management/assets/pairings/:entityNumber',
        ],
    },
    {
        element: <LocationHistory />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: [
            '/asset-management/assets/location/:entityNumber',
        ],
    },
    {
        element: <LocationHistory />,
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        path: [
            '/asset-management/assets/location',
        ],
    },
];

export default SensorDataRouter;
