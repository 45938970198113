import useClasses from 'hooks/useClasses';
import React from 'react';
import Card from 'shared-components/Card';

const styles = {
    contentClass: {
        height: '100%',
        width: '100%',
    },
};
const ManageBookings = () => {
    const classes = useClasses(styles);
    const env = `.${process.env.REACT_APP_ENVIRONMENT}`;

    return (
        <Card contentClass={classes.contentClass} fullHeight zeroSidePadding zeroVerticalPadding>
            <iframe
                allowFullScreen
                height="100%"
                src={`https://skycellbooking${env !== '.prod' ? env : ''}.skymind.com/orders`}
                style={{ border: 'none' }}
                title="Manage Bookings"
                width="100%"
            />
        </Card>
    );
};

export default ManageBookings;
